<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="220" height="231" viewBox="0 0 220 231" fill="none"
    class="play-icon cursor-pointer">
    <g filter="url(#filter0_d_1452_53832)">
      <path d="M144.164 115.281L109.763 135.281L75.3672 155.281V115.281V75.2812L109.763 95.2812L144.164 115.281Z"
        fill="white" />
    </g>
    <defs>
      <filter id="filter0_d_1452_53832" x="0.367188" y="0.28125" width="218.797" height="230"
        filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation="37.5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1452_53832" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1452_53832" result="shape" />
      </filter>
    </defs>
  </svg>
</template>
