<template>
  <div class="dropdown currency-dropdown">
    <div class="nav-link" role="button" data-bs-toggle="dropdown">
      <img :src="selectedCurrency.currency" :alt="selectedCurrency.language" />
      <span>{{ selectedCurrency.language }}</span>
      <fa icon="chevron-down"></fa>
    </div>
    <ul class="dropdown-menu py-0" aria-labelledby="currencyDropdown">
      <li v-for="option in countries" :key="option.language">
        <div
          class="dropdown-item currency-item"
          :class="option.language == selectedCurrency.language ? 'active' : ''"
          @click="selectLanguage(option)"
        >
          <img :src="option.currency" :alt="`${option.language} currency`" />
          <span>{{ option.language }}</span>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
      isCollapsed: true,
      selectedCurrency: {},
      countries: [
        {
          language: "USD",
          currency: require("@/assets/img/currency/USD.svg"),
        },
        {
          language: "GBP",
          currency: require("@/assets/img/currency/GBP.svg"),
        },
        {
          language: "AUD",
          currency: require("@/assets/img/currency/AUD.svg"),
        },
        {
          language: "CAD",
          currency: require("@/assets/img/currency/CAD.svg"),
        },
        {
          language: "EUR",
          currency: require("@/assets/img/currency/EUR.svg"),
        },
      ],
    };
  },
  props: {
    defaultCurrency: {
      type: Object,
      default: {
        language: "USD",
        currency: require("@/assets/img/currency/USD.svg"),
      },
    },
  },
  mounted() {
    this.selectedCurrency = this.defaultCurrency;
  },
  methods: {
    selectLanguage(option) {
      this.selectedCurrency = option;
    },
  },
};
</script>

<style lang="scss">
.currency-dropdown {
  position: relative;

  .nav-link,
  .currency-item {
    border-radius: 65px;
    border: 2px solid var(--gray);
    background: var(--white1);
    color: var(--black2);
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 5px 10px;
    cursor: pointer;

    img {
      width: 23.85px;
      height: 18px;
    }
    &.show {
      svg {
        transform: rotate(-180deg);
      }
    }
  }
  .dropdown-menu {
    top: 30px;
    min-width: 109px;
    border-radius: 10px;
    overflow: hidden;
    flex-direction: column;
    gap: 9px;

    &.show {
      display: flex;
    }
    .currency-item {
      border: none;
      border-radius: 0;
      box-shadow: none !important;
      &.active,
      &:focus,
      &:active {
        background: var(--gradient);
        border-color: var(--primary);
        color: var(--white1);
      }
    }
  }
}
</style>
