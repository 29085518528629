<!-- eslint-disable prettier/prettier -->
<template>
  <!-- start header section -->
  <div class="header-wrap fixed-top">
    <nav class="navbar navbar-expand-xl">
      <div class="container-fluid">
        <!-- logo -->
        <router-link to="/" class="navbar-brand order-1" @click="mainPage">
          <img src="@/assets/img/logo.png" alt="logo" class="img-fluid" />
        </router-link>

        <!-- toggle button -->
        <button
          class="navbar-toggler order-3 order-xl-2"
          type="button"
          :class="{ collapsed: !isExpanded }"
          @click="isExpanded = !isExpanded"
        >
          <div class="navbar-toggler-icon line-1"></div>
          <div class="navbar-toggler-icon line-2"></div>
          <div class="navbar-toggler-icon line-3"></div>
        </button>

        <!-- nav links -->
        <div
          class="collapse navbar-collapse order-4 order-xl-3 justify-content-xxl-center collapse"
          id="navbarSupportedContent"
          :class="{ show: isExpanded }"
        >
          <ul class="navbar-nav mb-2 mb-xl-0">
            <li class="nav-item dropdown">
              <a
                class="nav-link"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span> About Us </span>
                <fa icon="chevron-down"></fa>
              </a>
              <ul class="dropdown-menu">
                <li>
                  <RouterLink
                    @click="toggleNavbar"
                    to="/about"
                    class="dropdown-item"
                  >
                    <svg
                      class="d-xl-none"
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      viewBox="0 0 25 25"
                      fill="none"
                    >
                      <path
                        d="M4.23438 12.5H20.2344M20.2344 12.5L14.2344 6.5M20.2344 12.5L14.2344 18.5"
                        stroke="#00F1AD"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <span>Who we are</span>
                  </RouterLink>
                </li>
                <li>
                  <RouterLink
                    @click="toggleNavbar"
                    to="/about/know"
                    class="dropdown-item"
                  >
                    <svg
                      class="d-xl-none"
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      viewBox="0 0 25 25"
                      fill="none"
                    >
                      <path
                        d="M4.23438 12.5H20.2344M20.2344 12.5L14.2344 6.5M20.2344 12.5L14.2344 18.5"
                        stroke="#00F1AD"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <span>Know Us in 1 min</span>
                  </RouterLink>
                </li>
                <!-- <li>
                  <RouterLink
                    @click="toggleNavbar"
                    to="/about/why-trust-us"
                    class="dropdown-item"
                  >
                    Why Trust Us
                  </RouterLink>
                </li> -->
                <!-- <li>
                  <RouterLink
                    @click="toggleNavbar"
                    to="/about/give-back"
                    class="dropdown-item"
                  >
                    Give Back
                  </RouterLink>
                </li> -->
                <!-- <li>
                  <RouterLink
                    @click="toggleNavbar"
                    to="/about/media-center"
                    class="dropdown-item"
                  >
                    Media Center
                  </RouterLink>
                </li> -->
                <li>
                  <RouterLink
                    @click="toggleNavbar"
                    to="/about/careers"
                    class="dropdown-item"
                  >
                    <svg
                      class="d-xl-none"
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      viewBox="0 0 25 25"
                      fill="none"
                    >
                      <path
                        d="M4.23438 12.5H20.2344M20.2344 12.5L14.2344 6.5M20.2344 12.5L14.2344 18.5"
                        stroke="#00F1AD"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <span>Careers</span>
                  </RouterLink>
                </li>
              </ul>
            </li>
            <li class="nav-item dropdown">
              <a
                class="nav-link"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span> Funding Plans </span>
                <fa icon="chevron-down"></fa>
              </a>
              <ul class="dropdown-menu">
                <li>
                  <RouterLink
                    @click="toggleNavbar"
                    to="/funding-plans"
                    class="dropdown-item"
                  >
                    <svg
                      class="d-xl-none"
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      viewBox="0 0 25 25"
                      fill="none"
                    >
                      <path
                        d="M4.23438 12.5H20.2344M20.2344 12.5L14.2344 6.5M20.2344 12.5L14.2344 18.5"
                        stroke="#00F1AD"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <span>2 - Step Account</span>
                  </RouterLink>
                </li>
                <li>
                  <RouterLink
                    @click="toggleNavbar"
                    to="/funding-plans/master"
                    class="dropdown-item"
                  >
                    <svg
                      class="d-xl-none"
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      viewBox="0 0 25 25"
                      fill="none"
                    >
                      <path
                        d="M4.23438 12.5H20.2344M20.2344 12.5L14.2344 6.5M20.2344 12.5L14.2344 18.5"
                        stroke="#00F1AD"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <span>1 - Step Account</span>
                  </RouterLink>
                </li>
                <li>
                  <RouterLink
                    @click="toggleNavbar"
                    to="/funding-plans/direct"
                    class="dropdown-item"
                  >
                    <svg
                      class="d-xl-none"
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      viewBox="0 0 25 25"
                      fill="none"
                    >
                      <path
                        d="M4.23438 12.5H20.2344M20.2344 12.5L14.2344 6.5M20.2344 12.5L14.2344 18.5"
                        stroke="#00F1AD"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <span>Direct Account</span>
                  </RouterLink>
                </li>
                <li>
                  <RouterLink
                    @click="toggleNavbar"
                    to="/funding-plans/scaling"
                    class="dropdown-item"
                  >
                    <svg
                      class="d-xl-none"
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      viewBox="0 0 25 25"
                      fill="none"
                    >
                      <path
                        d="M4.23438 12.5H20.2344M20.2344 12.5L14.2344 6.5M20.2344 12.5L14.2344 18.5"
                        stroke="#00F1AD"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <span>Scaling Plan</span>
                  </RouterLink>
                </li>
              </ul>
            </li>
            <li class="nav-item dropdown">
              <a
                class="nav-link"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span> Free Resources </span>
                <fa icon="chevron-down"></fa>
              </a>
              <ul class="dropdown-menu">
                <li>
                  <RouterLink
                    @click="toggleNavbar"
                    to="/free-resources"
                    class="dropdown-item"
                  >
                    <svg
                      class="d-xl-none"
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      viewBox="0 0 25 25"
                      fill="none"
                    >
                      <path
                        d="M4.23438 12.5H20.2344M20.2344 12.5L14.2344 6.5M20.2344 12.5L14.2344 18.5"
                        stroke="#00F1AD"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <span>Trading Tools</span>
                  </RouterLink>
                </li>
                <li>
                  <RouterLink
                    @click="toggleNavbar"
                    to="/free-resources/symbols"
                    class="dropdown-item"
                  >
                    <svg
                      class="d-xl-none"
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      viewBox="0 0 25 25"
                      fill="none"
                    >
                      <path
                        d="M4.23438 12.5H20.2344M20.2344 12.5L14.2344 6.5M20.2344 12.5L14.2344 18.5"
                        stroke="#00F1AD"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <span>Symbols</span>
                  </RouterLink>
                </li>
                <!-- <li>
                  <RouterLink
                    @click="toggleNavbar"
                    to="/free-resources/trading-magazine"
                    class="dropdown-item"
                  >
                    Trading Magazine
                  </RouterLink>
                </li> -->
                <li>
                  <RouterLink
                    @click="toggleNavbar"
                    to="/free-resources/economic-calendar"
                    class="dropdown-item"
                  >
                    <svg
                      class="d-xl-none"
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      viewBox="0 0 25 25"
                      fill="none"
                    >
                      <path
                        d="M4.23438 12.5H20.2344M20.2344 12.5L14.2344 6.5M20.2344 12.5L14.2344 18.5"
                        stroke="#00F1AD"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <span>Economic Calendar</span>
                  </RouterLink>
                </li>
                <li>
                  <RouterLink
                    @click="toggleNavbar"
                    to="/free-resources/genius-leaderboard"
                    class="dropdown-item"
                  >
                    <svg
                      class="d-xl-none"
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      viewBox="0 0 25 25"
                      fill="none"
                    >
                      <path
                        d="M4.23438 12.5H20.2344M20.2344 12.5L14.2344 6.5M20.2344 12.5L14.2344 18.5"
                        stroke="#00F1AD"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <span>Genius Leaderboard</span>
                  </RouterLink>
                </li>
                <li>
                  <RouterLink
                    @click="toggleNavbar"
                    to="/free-resources/certificates"
                    class="dropdown-item"
                  >
                    <svg
                      class="d-xl-none"
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      viewBox="0 0 25 25"
                      fill="none"
                    >
                      <path
                        d="M4.23438 12.5H20.2344M20.2344 12.5L14.2344 6.5M20.2344 12.5L14.2344 18.5"
                        stroke="#00F1AD"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <span>Certificates</span>
                  </RouterLink>
                </li>
              </ul>
            </li>
            <li class="nav-item dropdown">
              <a
                class="nav-link"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span> Get Help </span>
                <fa icon="chevron-down"></fa>
              </a>
              <ul class="dropdown-menu">
                <li>
                  <RouterLink
                    @click="toggleNavbar"
                    to="/faq"
                    class="dropdown-item"
                  >
                    <svg
                      class="d-xl-none"
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      viewBox="0 0 25 25"
                      fill="none"
                    >
                      <path
                        d="M4.23438 12.5H20.2344M20.2344 12.5L14.2344 6.5M20.2344 12.5L14.2344 18.5"
                        stroke="#00F1AD"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <span>FAQs</span>
                  </RouterLink>
                </li>
                <li>
                  <RouterLink
                    @click="toggleNavbar"
                    to="/customer-support"
                    class="dropdown-item"
                  >
                    <svg
                      class="d-xl-none"
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      viewBox="0 0 25 25"
                      fill="none"
                    >
                      <path
                        d="M4.23438 12.5H20.2344M20.2344 12.5L14.2344 6.5M20.2344 12.5L14.2344 18.5"
                        stroke="#00F1AD"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <span>Contact Us</span>
                  </RouterLink>
                </li>
              </ul>
            </li>
            <li class="nav-item dropdown">
              <a
                class="nav-link"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span> Education </span>
                <fa icon="chevron-down"></fa>
              </a>
              <ul class="dropdown-menu">
                <li>
                  <RouterLink
                    @click="toggleNavbar"
                    to="/academy"
                    class="dropdown-item"
                  >
                    <svg
                      class="d-xl-none"
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      viewBox="0 0 25 25"
                      fill="none"
                    >
                      <path
                        d="M4.23438 12.5H20.2344M20.2344 12.5L14.2344 6.5M20.2344 12.5L14.2344 18.5"
                        stroke="#00F1AD"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <span>PrimeFx School</span>
                  </RouterLink>
                </li>
                <li>
                  <RouterLink
                    @click="toggleNavbar"
                    to="/performance-coach"
                    class="dropdown-item"
                  >
                    <svg
                      class="d-xl-none"
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      viewBox="0 0 25 25"
                      fill="none"
                    >
                      <path
                        d="M4.23438 12.5H20.2344M20.2344 12.5L14.2344 6.5M20.2344 12.5L14.2344 18.5"
                        stroke="#00F1AD"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <span>Trading Coach</span>
                  </RouterLink>
                </li>
                <li>
                  <RouterLink
                    @click="toggleNavbar"
                    to="/education/blogs"
                    class="dropdown-item"
                  >
                    <svg
                      class="d-xl-none"
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      viewBox="0 0 25 25"
                      fill="none"
                    >
                      <path
                        d="M4.23438 12.5H20.2344M20.2344 12.5L14.2344 6.5M20.2344 12.5L14.2344 18.5"
                        stroke="#00F1AD"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <span>Blogs</span>
                  </RouterLink>
                </li>
              </ul>
            </li>
            <li class="nav-item">
              <RouterLink
                @click="toggleNavbar"
                to="/become-partner"
                class="nav-link"
              >
                <span>Affiliate Program</span>
              </RouterLink>
            </li>
          </ul>

          <!-- btns group -->
          <div
            class="btns-group ms-auto order-2 order-xl-4 d-flex d-md-none flex-column"
          >
            <!-- <router-link
              to="/free-dashboard-tour"
              class="common-btn w-100"
              aria-current="page"
            > -->
            <button
              class="common-btn w-100"
              data-bs-toggle="modal"
              data-bs-target="#videoModal"
            >
              <span>Free Dashboard</span>
            </button>
            <!-- </router-link> -->
            <a
              href="https://trader.holaprime.com/en/sign-up"
              class="common-outline-btn w-100"
              aria-current="page"
              target="_blank"
            >
              <span>Sign Up</span>
            </a>
          </div>
        </div>

        <!-- btns group -->
        <div
          class="btns-group ms-auto order-2 order-xl-4 d-none d-md-flex mt-0"
        >
          <!-- search button -->
          <!-- <button class="btn border-0 p-0 search-btn" data-bs-toggle="modal" data-bs-target="#searchModal">
            <fa icon="fa-magnifying-glass" class="text-white"></fa>
          </button> -->
          <!-- flag dropdown -->
          <div class="dropdown flag-dropdown">
            <a
              class="nav-link"
              href="#"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img
                :src="selectedCountry.flag"
                :alt="selectedCountry.language"
              />
              <fa icon="chevron-down"></fa>
            </a>
            <ul class="dropdown-menu" aria-labelledby="flagDropdown">
              <li v-for="option in countries" :key="option.language">
                <a
                  class="dropdown-item flag-item"
                  :class="
                    option.language == selectedCountry.language ? 'active' : ''
                  "
                  href="#"
                  @click="selectLanguage(option)"
                >
                  <img :src="option.flag" :alt="`${option.language} flag`" />
                  {{ option.language }}
                </a>
              </li>
            </ul>
          </div>

          <a
          href="https://trader.holaprime.com/en/sign-up"
            class="common-btn dashboard-btn d-none d-sm-flex"
            target="_blank"
          >
            <span>Register</span>
        </a>
          <!-- </router-link> -->
          <a
            href="https://trader.holaprime.com/en/sign-in"
            class="common-outline-btn text-decoration-none user-btn"
            aria-current="page"
            target="_blank"
          >
            <fa :icon="['far', 'user']" />
            <!-- <span class="d-none d-sm-block">Login</span> -->
          </a>
        </div>
      </div>
    </nav>
  </div>

  <!-- Search Modal -->
  <div
    class="modal fade search-modal"
    id="searchModal"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-scrollable">
      <div class="modal-content bg-white border-0">
        <div class="modal-header border-0">
          <input
            type="text"
            class="form-control border-0"
            placeholder="Search here......"
          />
          <button type="submit" class="common-btn submit-btn">
            <fa icon="fa fa-search"></fa>
          </button>
        </div>
        <div class="modal-body">
          <div class="title_box">
            <div class="d-flex justify-content-between">
              <h4>Posts</h4>
              <!-- <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button> -->
            </div>
          </div>
          <div class="post-box">
            <img
              src="@/assets/img/bg/bg-gray.png"
              alt="bg-gray"
              class="img-fluid"
            />
            <div>
              <h3>Title Space for long ones</h3>
              <p class="text">
                Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed
                diam nonummy nibh euismod tincidunt Lorem ipsum dolor sit amet,
                consectetuer adipiscing elit, sed diam nonummy nibh euismod
                tinciduntLorem ipsum dolor sit amet, consectetuer adipiscing
                elit, sed diam nonummy nibh euismod tincidunt...
              </p>
              <p class="fw-500 mb-0">29 Nov 2023</p>
            </div>
          </div>
          <div class="post-box">
            <img
              src="@/assets/img/bg/bg-gray.png"
              alt="bg-gray"
              class="img-fluid"
            />
            <div>
              <h3>Title Space for long ones</h3>
              <p class="text">
                Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed
                diam nonummy nibh euismod tincidunt Lorem ipsum dolor sit amet,
                consectetuer adipiscing elit, sed diam nonummy nibh euismod
                tinciduntLorem ipsum dolor sit amet, consectetuer adipiscing
                elit, sed diam nonummy nibh euismod tincidunt...
              </p>
              <p class="fw-500 mb-0">29 Nov 2023</p>
            </div>
          </div>
          <div class="post-box border-0">
            <img
              src="@/assets/img/bg/bg-gray.png"
              alt="bg-gray"
              class="img-fluid"
            />
            <div>
              <h3>Title Space for long ones</h3>
              <p class="text">
                Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed
                diam nonummy nibh euismod tincidunt Lorem ipsum dolor sit amet,
                consectetuer adipiscing elit, sed diam nonummy nibh euismod
                tinciduntLorem ipsum dolor sit amet, consectetuer adipiscing
                elit, sed diam nonummy nibh euismod tincidunt...
              </p>
              <p class="fw-500 mb-0">29 Nov 2023</p>
            </div>
          </div>
        </div>
        <div class="modal-footer border-0">
          <button
            type="button"
            class="text-blue-dark me-auto w-fit me-auto my-0 border-0 fw-700"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="redirect()"
          >
            View all results
          </button>
          <!-- <router-link
            data-bs-dismiss="modal"
            to="/search"
            class="text-blue-dark me-auto w-fit me-auto my-0"
          >
            View all results
          </router-link> -->
        </div>
      </div>
    </div>
  </div>

  <div
    class="modal fade video-model"
    id="videoModal"
    tabindex="-1"
    aria-hidden="true"
  >
    <div
      class="modal-dialog modal-dialog-scrollable d-flex align-content-center px-sm-3 px-2"
    >
      <div class="modal-content border-0 my-auto">
        <div class="video-container mb-0 border">
          <video
            ref="video"
            @pause="pauseVideo"
            @ended="isPlay = false"
            src="@/assets/video/sample_video.mp4"
            class="d-block w-100"
            controls
            :class="{ 'd-none': !isPlay }"
          ></video>
          <img
            v-if="!isPlay"
            src="@/assets/img/scaling-plan/challenge-phase-image.png"
            alt="account-overview"
            class="bg-img img-fluid"
          />
          <div
            v-if="!isPlay"
            @click="playVideo()"
            class="position-absolute top-50 start-50 translate-middle z-3"
          >
            <svg
              class="play-icon model-play-icon cursor"
              xmlns="http://www.w3.org/2000/svg"
              width="220"
              height="231"
              viewBox="0 0 220 231"
              fill="none"
            >
              <g filter="url(#filter0_d_390_30505)">
                <path
                  d="M144.09 115.228L109.574 135.295L75.0625 155.362V115.228V75.0938L109.574 95.1609L144.09 115.228Z"
                  fill="white"
                />
              </g>
              <defs>
                <filter
                  id="filter0_d_390_30505"
                  x="0.0625"
                  y="0.09375"
                  width="219.027"
                  height="230.27"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset />
                  <feGaussianBlur stdDeviation="37.5" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_390_30505"
                  />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_390_30505"
                    result="shape"
                  />
                </filter>
              </defs>
            </svg>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import router from "@/router";

export default {
  name: "Header",
  data() {
    return {
      isPlay: false,
      isComparison: true,
      isExpanded: false,
      selectedCountry: {
        language: "English",
        flag: require("@/assets/img/flag/english.svg"), // Use require here
      },
      countries: [
        {
          language: "English",
          flag: require("@/assets/img/flag/english.svg"),
        },
        {
          language: "Spanish",
          flag: require("@/assets/img/flag/spanish.svg"),
        },
        {
          language: "Chinese",
          flag: require("@/assets/img/flag/chines.svg"),
        },
        {
          language: "Indonesian",
          flag: require("@/assets/img/flag/indonesian.svg"),
        },
      ],
    };
  },
  methods: {
    selectLanguage(option) {
      this.selectedCountry = option;
    },
    redirect() {
      router.push("/search");
    },
    toggleNavbar() {
      const screenWidth = window.innerWidth;
      if (screenWidth < 1200) {
        const navbarToggler = document.querySelector(".navbar-toggler");
        if (navbarToggler) {
          navbarToggler.click();
        }
      }
    },
    mainPage() {
      const screenWidth = window.innerWidth;
      if (screenWidth < 1200) {
        const navbarToggler = document.querySelector(".navbar-toggler");
        if (navbarToggler && this.isExpanded) {
          navbarToggler.click();
        }
      }
    },

    playVideo() {
      this.isPlay = true;
      this.$nextTick(() => {
        const video = this.$refs.video;

        if (video) {
          video.play();
        } else {
          console.error("Video element not found!");
        }
      });
    },
    pauseVideo() {
      this.isPlay = false;
      const video = this.$refs.video;

      if (video) {
        video.pause();
      } else {
        console.error("Video element not found!");
      }
    },
  },
};
</script>
