<template>
  <div v-if="
    $route.name != 'login' &&
    $route.name != 'sign-up' &&
    $route.name != 'confirmation-code' &&
    $route.name != 'free-dashboard-tour' &&
    $route.name != 'new-password' &&
    $route.name != 'register' &&
    $route.name != 'stepverification'
  ">
    <Header />
    <div class="page-content">
      <router-view v-if="$route.name == 'home'" @toggleMessage="toggleMessage" />
      <router-view v-else />
    </div>
    <Footer :baseClass="$route.name == 'free-resources-trading-magazine'
      ? 'bg-primary'
      : $route.name == 'education-blogs' ||
        $route.name == 'education-academy' ||
        $route.name == 'free-resources-trading-applications' ||
        $route.name == 'free-resources-economic-calendar' ||
        $route.name == 'free-resources-genius-leaderboard' ||
        $route.name == 'free-resources-certificates' ||
        $route.name == 'faq' ||
        $route.name == 'search' ||
        $route.name == 'free-resources-symbols' ||
        $route.name == 'careers' ||
        $route.name == 'about'
        ? 'bg-background'
        : $route.name == 'home' ? 'bg-black' : ''
      " />
  </div>

  <router-view v-else />
</template>

<script>
import Header from "@/components/layout/Header.vue";
import Footer from "@/components/layout/Footer.vue";

export default {
  name: "App",
  components: {
    Header,
    Footer,
  },
};
</script>
